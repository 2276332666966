import React, { useContext } from "react"
import RedirectContext from "../context/redirect-context"
import Close from "../images/close.svg"


const RedirectPopup = () => {
  const [redirectActive, setRedirectActive] = useContext(RedirectContext);
  const redirect = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('returningPatient', true)
    }
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag('event', 'conversion', { 
        'send_to': 'AW-667741527/v9F3CLDA38MBENfas74C', 
        'value': 1.0, 
        'currency': 'USD' 
      })
    }
    window.open("https://patientportal.advancedmd.com/147333/onlinescheduling", '__blank');
    setRedirectActive(false)
    
  }

  const cancelRedirect = () => {
    setRedirectActive(false)
  }

  return (
    <div className="redirect-container">
      <div className="redirect-flex">
        <div className="redirect-popup">
          <div className="close-button" onClick={() => {setRedirectActive(false)}}>
            <Close style={{ color: 'white'}}/>
          </div>
          <p>
            To schedule an appointment, you will be redirected to our
            external appointment booking website
          </p>
          <a href="https://patientportal.advancedmd.com/147333/onlinescheduling" target="__blank" role="button">
            <button 
              className="redirect-confirm"
              onClick={redirect}
            >Continue</button>
          </a>
        </div>
        
      </div>
      <div className="cancel-redirect-mask"
        onClick={cancelRedirect}
      >
      </div>
    </div>
  )
}

export default RedirectPopup