import React, { useState, useEffect, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";

import RedirectContext from "../context/redirect-context";
import Button from "./button";
import { Link } from "gatsby";

// Rewrite setItem function to fire window event when localstorage is written to
// Added this higher up into context, window object change should propagate down, make sure this is the case
/*
const originalSetItem = typeof localStorage !== 'undefined' ? localStorage.setItem : null;

if (typeof localStorage !== 'undefined') {
  localStorage.setItem = function(key, value) {
    const event = new Event('itemInserted');

    event.value = value
    event.key = key;
    
    document.dispatchEvent(event);
    
    originalSetItem.apply(this, arguments);
  }
}*/

const RedirectButton = ({ classes, buttonID, children, noStyle, light }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appointmentProviderUrl
        }
      }
    }
  `);
  const [redirectActive, setRedirectActive] = useContext(RedirectContext);
  const [returningPatient, setReturningPatient] = useState(
    typeof localStorage !== "undefined"
      ? localStorage.getItem("returningPatient")
      : null
  );

  useEffect(() => {
    const updateReturingPatientState = ev => {
      if (ev.key === "returningPatient" && ev.value === true) {
        setReturningPatient("true");
      }
      return;
    };
    if (typeof document !== "undefined") {
      document.addEventListener("itemInserted", updateReturingPatientState);
      return () => {
        document.removeEventListener(
          "itemInserted",
          updateReturingPatientState
        );
      };
    }
  }, []);

  const direct = () => {
    // If user is returning patient, redirect immediately. Otherwise, show modal
    if (returningPatient === "true") {
      //window.location.href="https://www.google.com"
      window.open(data.site.siteMetadata.appointmentProviderUrl, "__blank");
    } else {
      setRedirectActive(true);
    }
  };

  if (noStyle) {
    return (
      <button className={classes} onClick={direct}>
        {children}
      </button>
    );
  } else {
    return (
      <>
        <Link to={"/contact-us/"}>
          <Button
            light={light ? true : null}
            //customBehavior={direct}
            customClasses={classes}
          >
            {children}
          </Button>
        </Link>
      </>
    );
  }
};

export default RedirectButton;
