import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Header from "./header";
import Footer from "./footer";
import StickyNav from "./stickynav";
import RedirectPopup from "./redirect-popup";
import RedirectContext from "../context/redirect-context";

const Layout = ({
  children,
  contactForm,
  logo,
  map = true,
  other = false,
  hero
}) => {
  /*const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  const [redirectActive] = useContext(RedirectContext);

  return (
    <>
      <ReactCSSTransitionGroup
        transitionName="popup"
        transitionEnter={true}
        transitionEnterTimeout={300}
        transitionLeave={false}
      >
        {redirectActive ? <RedirectPopup /> : null}
      </ReactCSSTransitionGroup>

      <Header showHero={hero} />
      <main className="mb-10">{children}</main>

      <StickyNav />
      <Footer
        contactForm={contactForm === false ? false : true}
        map={map}
        logo={logo === false ? false : true}
        other={other}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
