import React, { useState, useEffect } from "react"
import Call from "../images/call.svg"
import Calendar from "../images/calendar.svg"
import RedirectButton from "../components/redirect-button"
import "./layout.css"

const StickyNav = ({ setRedirectPopupActive }) => {
  const [yOffset, setYOffset] = useState([0,0])
  useEffect(() => {
    setYOffset([window.pageYOffset, window.pageYOffset])
    
    const handleScroll = () => {
      setYOffset((prevState) => ([prevState[1], window.pageYOffset]))
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  let scrolledUp = (yOffset[0] >= yOffset[1] && yOffset[0] > 5) ? true : false
  return (
    <>
      <div className="sticky-nav mobile-only-flex" style={ scrolledUp ? {transform: 'translateY(0px)'} : {transform: 'translateY(100px)'} }>
        <div style={{width: '50%'}}>
          <a href="tel:1-503-766-4338">
            <button 
              className="w-full h-full bg-neutral-gray-200"
              style={{borderRight: '1px solid #ccc'}}>
              <div className="flex justify-center">
                <Call className="pt-1 pr-1" /> <p>{"  Call Us Now!"}</p>
              </div>
            </button>
          </a>
        </div>
        <div style={{width: '50%'}}>
          <RedirectButton
            classes="w-full h-full bg-neutral-gray-200"
            onClick={setRedirectPopupActive}
            buttonID="sticky_nav"
            noStyle
          >
            <div className="flex justify-center items-center w-full h-full">
              <Calendar className="pt-1 pr-1" /><p>Schedule Appt.</p>
            </div>
          </RedirectButton>
        </div>
      </div>
    </>
  )
}

export default StickyNav
