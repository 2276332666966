import { Link } from "gatsby";
import React from "react";
import Logo from "../images/logofinal.svg";
import GMap from "../components/GMap.js";
import RedirectButton from "../components/redirect-button.js";
import Button from "../components/button.js";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({ contactForm, logo, map = true, other }) => {
  const hours = {
    "Mon-Thurs": "9:00am - 6:00pm",
    "Fri, Sat, Sun": "Closed"
  };

  return (
    <>
      {contactForm ? (
        <div className="desktop-max-width w-full bg-blue-600 relative pl-8 pr-8 pt-16">
          <div className="flex flex-col justify-center text-center text-white text-base">
            <h2 className="text-2xl font-bold mb-4">Ready to Get Started?</h2>
            <p className="text-base mb-4">
              You are just a few clicks away from scheduling your first
              appointment!
            </p>
            <RedirectButton classes="max-w-sm self-center" light>
              Schedule appointment online
            </RedirectButton>
            <Link to="/contact-us">
              <p className="pt-4 pb-4">Still have questions?</p>
            </Link>
            <Button light linkTo="/contact-us">
              Send us a message
            </Button>
            <p className="pt-4 pb-4">Need financing?</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="mb-16"
              href="https://www.carecredit.com/go/827VDF/bb3poscustacqbtnclk08132020/?dtc=Ds5X&promocode=CLT/"
            >
              <Button light visual>
                Visit our financing provider
              </Button>
            </a>
          </div>
        </div>
      ) : null}

      <div
        style={
          other
            ? {
                padding: "35px 30px 25px 30px",
                textAlign: "center",
                color: "#2578bf",
                fontSize: 14,
                backgroundColor: "#F9F9F9"
              }
            : {
                padding: "35px 30px 25px 30px",
                textAlign: "center",
                color: "#2578bf",
                fontSize: 14
              }
        }
      >
        <div
          className="flex-center-container"
          style={{ marginBottom: 24 }}
        ></div>
        {other ? (
          <div className="flex justify-center flex-col w-full">
            <div className="text-gray-900 font-semibold text-2xl mb-4">
              Providing service to the greater Portland area
            </div>
            <div className="text-gray-900 font-normal text-lg mb-8 max-w-md self-center">
              Visit our beautiful, state of the art office conveniently located
              for residents of Clackamas County, Multnomah County and Clark
              County.
            </div>
          </div>
        ) : null}
        {logo ? (
          <div className="w-full flex justify-center">
            <Logo
              className="pb-1 mb-4"
              style={{ maxHeight: 72 }}
              alt="Multnomah Medical Clinic"
            />
          </div>
        ) : null}
        <div className="text-center pb-8 text-base tracking-wide">
          <a
            href="https://www.bbb.org/us/or/happy-valley/profile/clinic/multnomah-medical-clinic-1296-1000108743/#sealclick"
            target="_blank"
            rel="nofollow"
          >
            <StaticImage
              src="https://seal-alaskaoregonwesternwashington.bbb.org/seals/black-seal-150-110-bbb-1000108743.png"
              alt="Multnomah Medical Clinic BBB Business Review"
            />
          </a>

          {logo ? (
            <>
              <p className="mt-4">
                <a
                  style={{ color: "#2578bf" }}
                  href="https://maps.google.com/maps?daddr=45.456350,-122.569120&amp;ll="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  9200 SE 91st Ave Suite 300
                  <br />
                  Happy Valley, OR 97086
                </a>
                <br />
                Phone:{" "}
                <a style={{ color: "#2578bf" }} href="tel:1-503-766-4338">
                  (503) 766-4338
                </a>
                <br></br>
                Fax: (971) 803-7953
              </p>
            </>
          ) : (
            <>
              <p className="mt-4">
                <a
                  style={{ color: "#2578bf" }}
                  href="https://maps.google.com/maps?daddr=45.456350,-122.569120&amp;ll="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Multnomah Medical Clinic
                  <br />
                  9200 SE 91st Ave Suite 300
                  <br />
                  Happy Valley, OR 97086
                </a>
                <br />
                Phone:{" "}
                <a style={{ color: "#2578bf" }} href="tel:1-503-766-4338">
                  (503) 766-4338
                </a>
                <br></br>
                Fax: (971) 803-7953
              </p>
            </>
          )}
        </div>
        <div className="flex justify-center">
          {map === false ? null : (
            <div className="w-full mb-8" style={{ maxWidth: 500 }}>
              <GMap />
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <div
            className="text-base max-w-xs sm:max-w-sm"
            style={{
              display: "table",
              width: "100%",
              maxWidth: 500,
              marginBottom: 30
            }}
          >
            {Object.keys(hours).map((key, index) => (
              <div key={"hour" + index} style={{ display: "table-row" }}>
                <span style={{ display: "table-cell", textAlign: "left" }}>
                  {key}
                </span>
                <span style={{ display: "table-cell", textAlign: "right" }}>
                  {hours[key]}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div></div>
      <footer className="bg-blue-700 text-sm text-white text-center w-full py-4">
        <div className="mb-2">HSA and FSA accepted here.</div>
        {/*<div>
          <sup>*</sup>0% financing is only availabe W.A.C.
        </div>*/}
        <div className="mb-2">
          <Link to="/privacy-disclosure/" className="m4-2">
            View privacy policies
          </Link>
        </div>
        <div>© 2024 Multnomah Medical | All Rights Reserved</div>
      </footer>
    </>
  );
};

export default Footer;
