import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import addToMailchimp from "gatsby-plugin-mailchimp";

const MailchimpForm = () => {
  const [status, setStatus] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      treatment: Yup.string().required("Required")
    }),
    onSubmit: (values, { resetForm }) => {
      addToMailchimp(values.email, {
        FNAME: values.firstName,
        LNAME: values.lastName
        // TREATMENT: values.treatment,
      });
      setSubmitted(true);
    }
  });

  const convertToFormData = formObj => {
    const formData = new FormData();
    Object.keys(formObj).map(key => {
      formData.append(key, formObj[key] !== undefined ? formObj[key] : "");
      return;
    });
    return formData;
  };

  const submitForm = (formData, resetForm) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "post",
      "https://multnomahmedical.us14.list-manage.com/subscribe/post?u=313545590a6cdc0893b8d4793&amp;id=5c77dc0dca"
    );
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(formData);
    resetForm();
    /*typeof window !== "undefined" &&
      window.gtag('event', 'form_submit', { send_to: "UA-154763728-1" });
    typeof window !== "undefined" &&
      window.gtag('event', 'conversion', { 'send_to': 'AW-667741527/Eo2lCIjbz8MBENfas74C' });*/
  };

  const inputClass =
    "shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline";
  const labelClass = "block text-black text-md font-bold mb-1";

  return (
    <>
      <form
        style={{ marginBottom: 0, alignSelf: "flex-end" }}
        action="https://multnomahmedical.us14.list-manage.com/subscribe/post?u=313545590a6cdc0893b8d4793&amp;id=5c77dc0dca"
        method="post"
        onSubmit={formik.handleSubmit}
        className=" md:px-12 pb-4 mb-2"
      >
        {submitted ? (
          <div className="text-black font-bold">
            You're signed up! You'll receive the Ebook shortly.
          </div>
        ) : (
          <>
            <div className="mb-6">
              <div>
                <label
                  className={labelClass}
                  htmlFor="mce-FNAME"
                  for="mce-FNAME"
                >
                  First Name
                </label>
                <input
                  className={inputClass}
                  name="FNAME"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-600 text-sm mb-3">
                    {formik.errors.firstName}
                  </div>
                ) : (
                  <div className="mb-2"></div>
                )}
              </div>
              {/*<div>
                <label
                  className={labelClass}
                  htmlFor="mce-LNAME"
                  for="mce-LNAME"
                >
                  Last Name
                </label>
                <input
                  className={inputClass}
                  name="LNAME"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-600 text-sm mb-3">
                    {formik.errors.lastName}
                  </div>
                ) : (
                  <div className="mb-2"></div>
                )}
                </div>*/}
              <div>
                <label
                  className={labelClass}
                  htmlFor="mce-EMAIL"
                  for="mce-EMAIL"
                >
                  Email address
                </label>
                <input
                  className={inputClass}
                  name="EMAIL"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-600 text-sm mb-3">
                    {formik.errors.email}
                  </div>
                ) : (
                  <div className="mb-4"></div>
                )}
              </div>
              <div>
                <label
                  className={labelClass}
                  htmlFor="mce-TREATMENT"
                  for="mce-TREATMENT"
                >
                  Treatment of Interest
                </label>
                <select
                  className={inputClass}
                  name="TREATMENT"
                  {...formik.getFieldProps("treatment")}
                >
                  <option value="">Select a treatment</option>
                  <option value="neuropathy">Neuropathy</option>
                  <option value="erectile-dysfunction">
                    Erectile Dysfunction
                  </option>
                  <option value="aesthetics">Aesthetics</option>
                  <option value="just-looking">Just Looking</option>
                </select>
                {formik.touched.treatment && formik.errors.treatment ? (
                  <div className="text-red-600 text-sm mb-3">
                    {formik.errors.treatment}
                  </div>
                ) : (
                  <div className="mb-2"></div>
                )}
              </div>
            </div>
            <div style={{ width: "100%" }}>
              {status === "SUCCESS" ? (
                <p>Thanks! You'll recieve the E-book in your email shortly.</p>
              ) : (
                <input
                  className="px-5 py-3 rounded-lg shadow-lg 
            uppercase tracking-wider font-semibold text-sm w-full
            focus:outline-none focus:shadow-outline bg-gray-200 text-black hover:bg-gray-100 active:bg-gray-100 hover:cursor-pointer"
                  type="submit"
                  value="Get the Ebook"
                ></input>
              )}
              {status === "ERROR" && <p>Oops! There was an error.</p>}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default MailchimpForm;
