import React, { useEffect, useRef } from "react"
import "./layout.css"

const GMap = () => {
  const googleMap = useRef(null)

  const renderMap = () => {
    try {
      if (window) {
        const map = new window.google.maps.Map(googleMap.current, {
          zoom: 15,
          center: {
            lat: 45.45635,
            lng: -122.56912,
          },
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
        })

        new window.google.maps.Marker({
          position: {
            lat: 45.45635,
            lng: -122.56912,
          },
          map: map,
        })
      } else {
        return null
      }
    } catch (err) {
      setTimeout(() => {
        renderMap()
      }, 100)
    }
  }

  useEffect(() => {
    if (document.getElementById("mapAPI") === null) {
      const mapAPI = document.createElement("script")

      mapAPI.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDNBu10Ib4lfu6fZo7PmXFJ8o7L2JhOgUY"
      mapAPI.async = true
      mapAPI.defer = true
      mapAPI.id = "mapAPI"
      document.body.appendChild(mapAPI)

      mapAPI.addEventListener("load", renderMap())
    } else {
      renderMap()
    }
  }, [renderMap])
  return (
    <>
      <a
        href="https://www.google.com/maps/place/Multnomah+Medical+Clinic/@45.4564671,-122.5690755,15z/data=!4m5!3m4!1s0x0:0x2543d77ae7ba45d4!8m2!3d45.4564671!4d-122.5690755"
        target="_blank"
      >
        <div
          id="google-map"
          ref={googleMap}
          style={{ height: 300, border: "1px solid #eeeeee" }}
        ></div>
      </a>
    </>
  )
}

export default GMap
